import React, { useState, useEffect } from "react";
import {
  StarOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Card,
  Input,
  Form,
  Button,
  Tabs,
  message,
  Upload,
  Tag,
  Row,
  Col,
  Select,
  Modal,
} from "antd";
import "./adminPanel.css";
import AdminTable from "./AdminTable";
import axios from "axios";
import { customFetch } from "../customFetch";

const { TabPane } = Tabs;
const { Option } = Select;

const PY_API_URL = process.env.REACT_APP_PY_API_URL;

const AdminPanel = () => {
  const [messageApi, contextHolder] = message.useMessage();
  message.config({
    duration: 6,
  });
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Submitted",
    });
  };
  // State hooks for all inputs
  const [projectNumber, setProjectNumber] = useState("");
  const [program, setProgram] = useState("");
  const [pm, setPm] = useState("");
  const [customer, setCustomer] = useState("");
  const [project, setProject] = useState("");
  const [cdc, setCdc] = useState("");
  const [division, setDivision] = useState("");
  const [testType, setTestType] = useState("");
  const [group, setGroup] = useState("");
  const [costCenterProjectNumber, setCostCenterProjectNumber] = useState("");
  const [costCenterProgram, setCostCenterProgram] = useState("");
  const [costCenterPm, setCostCenterPm] = useState("");
  const [bckPm, setBckPm] = useState("");
  const [costCenterCdc, setCostCenterCdc] = useState("");
  const [chargeTo, setChargeTo] = useState("");
  const [fixtureName, setFixtureName] = useState("");
  const [climaticChamberName, setClimaticChamberName] = useState("");
  const [testName, setTestName] = useState("");
  const [testNumber, setTestNumber] = useState("");
  const [file, setFile] = useState(null);

  //////////////////////////// ETO PROJECT ////////////////////////////////////

  //modifying
  const [etoProjects, setEtoProjects] = useState({});
  const [selectedProject, setSelectedProject] = useState(null);
  const [modifiedProject, setModifiedProject] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = () => {
    customFetch(`${PY_API_URL}/api/get_projects_admin`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Full API Response:", data);

        const filteredProjects = Object.keys(data)
          .filter((key) => data[key].status === "Active")
          .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});

        setEtoProjects(filteredProjects);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleModifyProject = async () => {
    if (!selectedProject) {
      message.error("Please select a project to modify.");
      return;
    }

    try {
      const response = await axios.post(`${PY_API_URL}/api/modify_project`, {
        current_project_number: selectedProject,
        new_project_number: projectNumber,
        program: program,
        pm: pm,
        customer: customer,
        project: project,
        cdc: cdc,
        division: division,
      });

      if (response.status === 200 && response.data.success) {
        message.success(`Project ${selectedProject} successfully modified.`);
        fetchProjects();
        setSelectedProject(null);
        setModifiedProject("");
      } else {
        message.error(response.data.error || "Failed to modify the project.");
      }
    } catch (error) {
      console.error("Error modifying project:", error);
      message.error("An error occurred while modifying the project.");
    }
  };

  const ProjectColumns = [
    {
      title: "Project Number",
      dataIndex: "projectNumber",
      key: "projectNumber",
    },
    { title: "Program", dataIndex: "program", key: "program" },
    { title: "PM", dataIndex: "pm", key: "pm" },
    { title: "Customer", dataIndex: "customer", key: "customer" },
    { title: "Project", dataIndex: "project", key: "project" },
    { title: "CDC", dataIndex: "cdc", key: "cdc" },
    { title: "Division", dataIndex: "division", key: "division" },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  //////////////////////////// JOB TYPE ////////////////////////////////////

  const [jobTypes, setJobTypes] = useState({});
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [modifiedJobType, setModifiedJobType] = useState("");

  useEffect(() => {
    fetchJobTypes();
  }, []);

  const fetchJobTypes = () => {
    customFetch(`${PY_API_URL}/api/get_job_types_admin`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setJobTypes(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleModifyJobType = async () => {
    if (!selectedJobType) {
      message.error("Please select a job type to modify.");
      return;
    }

    try {
      const response = await axios.post(`${PY_API_URL}/api/modify_job_type`, {
        current_test_type: selectedJobType,
        new_test_type: testType,
        new_group: group,
      });

      if (response.status === 200 && response.data.success) {
        message.success(`Job Type ${selectedJobType} successfully modified.`);

        fetchJobTypes();

        setSelectedJobType(null);
        setTestType("");
        setGroup("");
      } else {
        message.error(response.data.error || "Failed to modify the job type.");
      }
    } catch (error) {
      console.error("Error modifying job type:", error);
      message.error("An error occurred while modifying the job type.");
    }
  };

  const JobTypeColumns = [
    {
      title: "Job Type",
      dataIndex: "test_type",
      key: "test_type",
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  //////////////////////////// COST CENTER DETAILS ////////////////////////////////////

  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [costCenters, setCostCenters] = useState([]);

  const fetchCostCenters = () => {
    customFetch(`${PY_API_URL}/api/get_cost_centers_admin`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Full API Response:", data);

        if (!Array.isArray(data)) {
          console.error("Invalid data format:", data);
          message.error("Failed to fetch cost centers: Invalid data format.");
          return;
        }

        setCostCenters(data);
      })
      .catch((error) => {
        console.error("Error fetching cost centers:", error);
        message.error("Error fetching cost centers");
      });
  };

  useEffect(() => {
    fetchCostCenters();
  }, []);

  const handleModifyCostCenter = async () => {
    if (!selectedCostCenter) {
      message.error("Please select a cost center to modify.");
      return;
    }

    try {
      const response = await axios.post(
        `${PY_API_URL}/api/modify_cost_center`,
        {
          current_project_number: selectedCostCenter,
          new_project_number: costCenterProjectNumber,
          program: costCenterProgram,
          pm: costCenterPm,
          bckPm: bckPm,
          cdc: costCenterCdc,
          chargeTo: chargeTo,
        }
      );

      if (response.status === 200 && response.data.success) {
        message.success(
          `Cost Center ${selectedCostCenter} successfully modified.`
        );

        fetchCostCenters();

        setSelectedCostCenter(null);
        setCostCenterProjectNumber("");
        setCostCenterProgram("");
        setCostCenterPm("");
        setBckPm("");
        setCostCenterCdc("");
        setChargeTo("");
      } else {
        message.error(
          response.data.error || "Failed to modify the cost center."
        );
      }
    } catch (error) {
      console.error("Error modifying cost center:", error);
      message.error("An error occurred while modifying the cost center.");
    }
  };

  const CostCenterColumns = [
    { title: "Cost Center", dataIndex: "costCenter", key: "costCenter" },
    {
      title: "Description",
      dataIndex: "costCenterDescription",
      key: "costCenterDescription",
    },
    {
      title: "Manager",
      dataIndex: "costCenterManager",
      key: "costCenterManager",
    },
    {
      title: "Approver",
      dataIndex: "costCenterApprover",
      key: "costCenterApprover",
    },
    { title: "CDC", dataIndex: "cdc", key: "cdc" },
    { title: "Charge To", dataIndex: "chargeTo", key: "chargeTo" },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  //////////////////////////// FIXTURE ////////////////////////////////////

  const [fixtures, setFixtures] = useState([]);
  const [selectedFixture, setSelectedFixture] = useState(null);
  console.log(selectedFixture);
  const [modifiedFixtureName, setModifiedFixtureName] = useState("");

  useEffect(() => {
    fetchFixtures();
  }, []);

  const fetchFixtures = () => {
    customFetch(`${PY_API_URL}/api/get_fixtures_admin`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFixtures(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleModifyFixture = async () => {
    if (!selectedFixture || !modifiedFixtureName) {
      message.error("Both the current and new fixture names are required.");
      return;
    }

    try {
      const response = await axios.post(`${PY_API_URL}/api/modify_fixture`, {
        current_fixture_name: selectedFixture,
        new_fixture_name: modifiedFixtureName,
      });

      if (response.status === 200 && response.data.success) {
        message.success(
          `Fixture "${selectedFixture}" successfully modified to "${modifiedFixtureName}"`,
          5
        );

        fetchFixtures();
        setSelectedFixture(null);
        setModifiedFixtureName("");
      } else {
        message.error(response.data.error || "Failed to modify the fixture.");
      }
    } catch (error) {
      console.error("Error modifying fixture:", error);
      message.error("An error occurred while modifying the fixture.");
    }
  };

  const FixtureColumns = [
    { title: "Fixture Name", dataIndex: "name", key: "name" },
    {
      title: "Calibration Expiration",
      dataIndex: "calibrationExpirationDate",
      key: "calibrationExpirationDate",
    },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  //////////////////////////// CLIMATIC CHAMBER ////////////////////////////////////

  const [climaticChambers, setClimaticChambers] = useState([]);
  const [selectedChamber, setSelectedChamber] = useState(null);
  const [modifiedChamberName, setModifiedChamberName] = useState("");

  const fetchClimaticChambers = () => {
    customFetch(`${PY_API_URL}/api/get_climatic_chambers_admin`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setClimaticChambers(data);
      })
      .catch((error) => {
        console.error("Error fetching climatic chambers:", error);
      });
  };

  useEffect(() => {
    fetchClimaticChambers();
  }, []);

  const handleModifyChamber = async () => {
    if (!selectedChamber || !modifiedChamberName) {
      message.error(
        "Both the current and new climatic chamber names are required."
      );
      return;
    }

    try {
      const response = await axios.post(
        `${PY_API_URL}/api/modify_climatic_chamber`,
        {
          current_chamber_name: selectedChamber,
          new_chamber_name: modifiedChamberName,
        }
      );

      if (response.status === 200 && response.data.success) {
        message.success(
          `Climatic Chamber "${selectedChamber}" successfully modified to "${modifiedChamberName}"`,
          5
        );

        fetchClimaticChambers();
        setSelectedChamber(null);
        setModifiedChamberName("");
      } else {
        message.error(
          response.data.error || "Failed to modify the climatic chamber."
        );
      }
    } catch (error) {
      console.error("Error modifying climatic chamber:", error);
      message.error("An error occurred while modifying the climatic chamber.");
    }
  };

  const ClimaticChamberColumns = [
    { title: "Chamber Name", dataIndex: "name", key: "name" },
    {
      title: "Calibration Expiration",
      dataIndex: "calibrationExpiringDate",
      key: "calibrationExpiringDate",
    },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  const handleProjectNumberChange = (e) => setProjectNumber(e.target.value);
  const handleProgramChange = (e) => setProgram(e.target.value);
  const handlePmChange = (e) => setPm(e.target.value);
  const handleCustomerChange = (e) => setCustomer(e.target.value);
  const handleProjectChange = (e) => setProject(e.target.value);
  const handleCdcChange = (e) => setCdc(e.target.value);
  const handleDivisionChange = (e) => setDivision(e.target.value);
  const handleTestTypeChange = (e) => setTestType(e.target.value);
  const handleGroupChange = (e) => setGroup(e.target.value);
  const handleCostCenterProjectNumberChange = (e) =>
    setCostCenterProjectNumber(e.target.value);
  const handleCostCenterProgramChange = (e) =>
    setCostCenterProgram(e.target.value);
  const handleCostCenterPmChange = (e) => setCostCenterPm(e.target.value);
  const handleBckPmChange = (e) => setBckPm(e.target.value);
  const handleCostCenterCdcChange = (e) => setCostCenterCdc(e.target.value);
  const handleChargeToChange = (e) => setChargeTo(e.target.value);
  const handleFixtureNameChange = (e) => setFixtureName(e.target.value);
  const handleClimaticChamberNameChange = (e) =>
    setClimaticChamberName(e.target.value);
  const handleTestNameChange = (e) => setTestName(e.target.value);
  const handleTestNumberChange = (e) => setTestNumber(e.target.value);

  // Handle button clicks
  const handleSubmitProject = () => {
    try {
      const response = axios.post(`${PY_API_URL}/api/add_new_eto_project`, {
        projectNumber: projectNumber,
        program: program,
        pm: pm,
        customer: customer,
        project: project,
        cdc: cdc,
        division: division,
      });
      // success()
      message.success("Added successfully");
    } catch (error) {
      console.error("Error submitting new eto project:", error);
    }
  };

  const handleSubmitJobType = () => {
    try {
      const response = axios.post(`${PY_API_URL}/api/add_new_job_type`, {
        testType: testType,
        group: group,
      });
      // success();
      message.success("Added successfully");
    } catch (error) {
      console.error("Error submitting new job type:", error);
    }
  };

  const handleSubmitCostCenter = () => {
    try {
      const response = axios.post(`${PY_API_URL}/api/add_cost_center`, {
        projectNumber: costCenterProjectNumber,
        program: costCenterProgram,
        pm: costCenterPm,
        bckpm: bckPm,
        cdc: costCenterCdc,
        chargeTo: chargeTo,
      });
      // success();
      message.success("Added successfully");
    } catch (error) {
      console.error("Error submitting new cost center:", error);
    }
  };

  const handleSubmitFixture = () => {
    try {
      const response = axios.post(`${PY_API_URL}/api/add_new_fixture`, {
        fixtureName: fixtureName,
      });
      // success()
      message.success("Added successfully");
    } catch (error) {
      console.error("Error submitting new fixture:", error);
    }
  };

  const handleSubmitClimaticChamber = () => {
    try {
      const response = axios.post(`${PY_API_URL}/api/add_climatic_chamber`, {
        chamberName: climaticChamberName,
      });
      // success();
      message.success("Added successfully");
    } catch (error) {
      console.error("Error submitting new climatic chamber:", error);
    }
  };

  const handleSubmitTestInstruction = async () => {
    if (!file || !testName || !testNumber) {
      message.error("Please complete all fields and upload a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("testName", testName);
    formData.append("testNumber", testNumber);

    try {
      const response = await axios.post(
        `${PY_API_URL}/api/add_new_test_instruction`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Test instruction added successfully");
    } catch (error) {
      message.error("Error submitting new test instruction");
      console.error("Error submitting new test instruction:", error);
    }
  };

  const handleUpload = (file) => {
    if (file.type !== "application/pdf") {
      message.error(`${file.name} is not a PDF file.`);
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false; // Prevent automatic upload
  };

  // Function to handle file upload
  // const handleUpload = (file) => {
  //   if (file.type !== 'application/pdf') {
  //     message.error(`${file.name} is not a PDF file.`);
  //     return Upload.LIST_IGNORE;
  //   }

  //   const formData = new FormData();
  //   formData.append('file', file);

  //   fetch(`${PY_API_URL}/api/add_new_test_instruction`, {
  //     method: 'POST',
  //     body: formData,
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.message) {
  //         message.success("Test instruction added successfully");
  //       } else {
  //         message.error("Failed to add test instruction");
  //       }
  //     })
  //     .catch(error => {
  //       message.error("An error occurred while uploading the file.");
  //       console.error("Error:", error);
  //     });

  //   return true;
  // };

  // const handleChange = (info) => {
  //   if (info.file.status === 'uploading') {
  //     console.log("uploading"); // Log uploading status
  //   }
  //   if (info.file.status === 'done') {
  //     console.log('uploaded'); // Log uploaded status
  //   }
  // };

  // useEffect(() => {
  //   customFetch(`${PY_API_URL}/api/get_project_list`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       let res = response.json()
  //       return res;
  //     })
  //     .then((data) => {
  //       setEtoProjects(data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);

  return (
    <div className="admin-panel-container">
      <h2
        style={{
          display: "inline-block",
          marginRight: "10px",
          marginBottom: "0",
        }}
      >
        Admin Panel
      </h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="ETO Project" key="1">
          <Tabs>
            <TabPane tab="Add" key="1.1">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Project Number">
                    <Input
                      value={projectNumber}
                      onChange={handleProjectNumberChange}
                      placeholder="T70330 Carryover WR MAP"
                    />
                  </Form.Item>
                  <Form.Item label="Program">
                    <Input
                      value={program}
                      onChange={handleProgramChange}
                      placeholder="Carryover WR MAP"
                    />
                  </Form.Item>
                  <Form.Item label="PM">
                    <Input
                      value={pm}
                      onChange={handlePmChange}
                      placeholder="Paolo Casalini"
                    />
                  </Form.Item>
                  <Form.Item label="Customer">
                    <Input
                      value={customer}
                      onChange={handleCustomerChange}
                      placeholder="Generic"
                    />
                  </Form.Item>
                  <Form.Item label="Project">
                    <Input
                      value={project}
                      onChange={handleProjectChange}
                      placeholder="T70330"
                    />
                  </Form.Item>
                  <Form.Item label="CDC">
                    <Input value={cdc} onChange={handleCdcChange} />
                  </Form.Item>
                  <Form.Item label="Division">
                    <Input
                      value={division}
                      onChange={handleDivisionChange}
                      placeholder="MAP (295)"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={handleSubmitProject}>
                      Submit New ETO Project
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </TabPane>
            <TabPane tab="Modify" key="1.2">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Select Project">
                    <Select
                      showSearch
                      placeholder="Select a project to modify"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        setSelectedProject(value);
                        const selected = etoProjects[value];
                        if (selected) {
                          setProjectNumber(value);
                          setProgram(selected.program || "");
                          setPm(selected.pm || "");
                          setCustomer(selected.customer || "");
                          setProject(selected.project || "");
                          setCdc(selected.cdc || "");
                          setDivision(selected.division || "");
                        }
                      }}
                    >
                      {Object.keys(etoProjects).map((project) => (
                        <Option key={project} value={project}>
                          {project}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {selectedProject && (
                    <>
                      <Form.Item label="Project Number">
                        <Input
                          value={projectNumber}
                          onChange={handleProjectNumberChange}
                        />
                      </Form.Item>
                      <Form.Item label="Program">
                        <Input value={program} onChange={handleProgramChange} />
                      </Form.Item>
                      <Form.Item label="PM">
                        <Input value={pm} onChange={handlePmChange} />
                      </Form.Item>
                      <Form.Item label="Customer">
                        <Input
                          value={customer}
                          onChange={handleCustomerChange}
                        />
                      </Form.Item>
                      <Form.Item label="Project">
                        <Input value={project} onChange={handleProjectChange} />
                      </Form.Item>
                      <Form.Item label="CDC">
                        <Input value={cdc} onChange={handleCdcChange} />
                      </Form.Item>
                      <Form.Item label="Division">
                        <Input
                          value={division}
                          onChange={handleDivisionChange}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" onClick={handleModifyProject}>
                          Modify Project
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form>
              </Card>
            </TabPane>

            <TabPane tab="Activate/Inactivate" key="1.3">
              <Card bordered>
                <AdminTable
                  fetchUrl={`${PY_API_URL}/api/get_projects_admin`}
                  updateUrl={`${PY_API_URL}/api/update_project_status`}
                  columns={ProjectColumns}
                  applyShowFilter={true}
                  primaryKey="projectNumber"
                />
              </Card>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Job Type" key="2">
          <Tabs>
            <TabPane tab="Add" key="2.1">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Test Type">
                    <Input
                      value={modifiedJobType}
                      onChange={handleTestTypeChange}
                      placeholder="ETO"
                    />
                  </Form.Item>
                  <Form.Item label="Group">
                    <Input
                      value={selectedJobType}
                      onChange={handleGroupChange}
                      placeholder="OH"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={handleSubmitJobType}>
                      Submit New Job Type
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </TabPane>

            <TabPane tab="Modify" key="2.2">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Select Job Type">
                    <Select
                      showSearch
                      placeholder="Select a job type to modify"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        setSelectedJobType(value);
                        const selected = jobTypes[value];

                        if (selected) {
                          setTestType(value);
                          setGroup(selected.group || ""); // ✅ Ensure `group` is always a string
                        }
                      }}
                    >
                      {Object.keys(jobTypes).map((jobType) => (
                        <Option key={jobType} value={jobType}>
                          {jobType}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {selectedJobType && (
                    <>
                      <Form.Item label="Test Type">
                        <Input
                          value={testType}
                          onChange={(e) => setTestType(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label="Group">
                        <Input
                          value={group}
                          onChange={(e) => setGroup(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" onClick={handleModifyJobType}>
                          Modify Job Type
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form>
              </Card>
            </TabPane>

            <TabPane tab="Activate/Inactivate" key="2.3">
              <Card bordered>
                <AdminTable
                  fetchUrl={`${PY_API_URL}/api/get_job_types_admin`}
                  updateUrl={`${PY_API_URL}/api/update_job_type_status`}
                  columns={JobTypeColumns}
                  primaryKey="test_type"
                />
              </Card>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Cost Center Details" key="3">
          <Tabs>
            <TabPane tab="Add" key="3.1">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Project Number">
                    <Input
                      value={costCenterProjectNumber}
                      onChange={handleCostCenterProjectNumberChange}
                      placeholder="904931"
                    />
                  </Form.Item>
                  <Form.Item label="Program">
                    <Input
                      value={costCenterProgram}
                      onChange={handleCostCenterProgramChange}
                      placeholder="OFerrari Serratura DX CPLH"
                    />
                  </Form.Item>
                  <Form.Item label="PM">
                    <Input
                      value={costCenterPm}
                      onChange={handleCostCenterPmChange}
                      placeholder="Carmelo Gioffre"
                    />
                  </Form.Item>
                  <Form.Item label="BCKPM">
                    <Input
                      value={bckPm}
                      onChange={handleBckPmChange}
                      placeholder="Carmelo Gioffre"
                    />
                  </Form.Item>
                  <Form.Item label="CDC">
                    <Input
                      value={costCenterCdc}
                      onChange={handleCostCenterCdcChange}
                      placeholder="OH"
                    />
                  </Form.Item>
                  <Form.Item label="Charge To">
                    <Input
                      value={chargeTo}
                      onChange={handleChargeToChange}
                      placeholder="Motrol (288)"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={handleSubmitCostCenter}>
                      Submit New Cost Center Details
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </TabPane>
            <TabPane tab="Modify" key="3.2">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Select Cost Center">
                    <Select
                      showSearch
                      placeholder="Select a cost center to modify"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        setSelectedCostCenter(value);
                        const selected = costCenters.find(
                          (center) => center.costCenter === value
                        );
                        if (selected) {
                          setCostCenterProjectNumber(selected.costCenter);
                          setCostCenterProgram(
                            selected.costCenterDescription || ""
                          );
                          setCostCenterPm(selected.costCenterManager || "");
                          setBckPm(selected.costCenterApprover || "");
                          setCostCenterCdc(selected.cdc || "");
                          setChargeTo(selected.chargeTo || "");
                        }
                      }}
                    >
                      {costCenters.map((center) => (
                        <Option
                          key={center.costCenter}
                          value={center.costCenter}
                        >
                          {center.costCenter}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {selectedCostCenter && (
                    <>
                      <Form.Item label="Program">
                        <Input
                          value={costCenterProgram}
                          onChange={(e) => setCostCenterProgram(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label="PM">
                        <Input
                          value={costCenterPm}
                          onChange={(e) => setCostCenterPm(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label="BCKPM">
                        <Input
                          value={bckPm}
                          onChange={(e) => setBckPm(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label="CDC">
                        <Input
                          value={costCenterCdc}
                          onChange={(e) => setCostCenterCdc(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label="Charge To">
                        <Input
                          value={chargeTo}
                          onChange={(e) => setChargeTo(e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button type="primary" onClick={handleModifyCostCenter}>
                          Modify Cost Center
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form>
              </Card>
            </TabPane>

            <TabPane tab="Activate/Inactivate" key="3.3">
              <Card bordered>
                <AdminTable
                  fetchUrl={`${PY_API_URL}/api/get_cost_centers_admin`}
                  updateUrl={`${PY_API_URL}/api/update_cost_center_status`}
                  columns={CostCenterColumns}
                  applyShowFilter={false}
                  primaryKey="costCenter"
                />
              </Card>
            </TabPane>
          </Tabs>
        </TabPane>

        <TabPane tab="Fixture" key="4">
          <Tabs>
            <TabPane tab="Add" key="4.1">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Fixture Name">
                    <Input
                      value={fixtureName}
                      onChange={handleFixtureNameChange}
                      placeholder="Galdabini Quasar 100"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={handleSubmitFixture}>
                      Submit New Fixture
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </TabPane>

            <TabPane tab="Modify" key="4.2">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Select Fixture">
                    <Select
                      showSearch
                      placeholder="Select a fixture to modify"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => setSelectedFixture(value)}
                    >
                      {fixtures.map((fixture, index) => (
                        <Option key={index} value={fixture.name}>
                          {fixture.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {selectedFixture && (
                    <>
                      <Form.Item label="New Fixture Name">
                        <Input
                          placeholder="Enter new fixture name"
                          value={modifiedFixtureName}
                          onChange={(e) =>
                            setModifiedFixtureName(e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={handleModifyFixture}
                          disabled={!selectedFixture || !modifiedFixtureName}
                        >
                          Modify Fixture
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form>
              </Card>
            </TabPane>

            <TabPane tab="Activate/Inactivate" key="4.3">
              <Card bordered>
                <AdminTable
                  fetchUrl={`${PY_API_URL}/api/get_fixtures_admin`}
                  updateUrl={`${PY_API_URL}/api/update_fixture_status`}
                  columns={FixtureColumns}
                  primaryKey="name"
                />
              </Card>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Climatic Chamber " key="5">
          <Tabs>
            <TabPane tab="Add " key="5.1">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Climatic Chamber Name">
                    <Input
                      value={climaticChamberName}
                      onChange={handleClimaticChamberNameChange}
                      placeholder="Camera 01 ACS"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={handleSubmitClimaticChamber}
                    >
                      Submit New Climatic Chamber
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </TabPane>
            <TabPane tab="Modify" key="5.2">
              <Card bordered>
                <Form layout="horizontal" style={{ maxWidth: 400 }}>
                  <Form.Item label="Select Climatic Chamber">
                    <Select
                      showSearch
                      placeholder="Select a climatic chamber to modify"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => setSelectedChamber(value)}
                    >
                      {climaticChambers.map((chamber, index) => (
                        <Option key={index} value={chamber.name}>
                          {chamber.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {selectedChamber && (
                    <>
                      <Form.Item label="New Climatic Chamber Name">
                        <Input
                          placeholder="Enter new climatic chamber name"
                          value={modifiedChamberName}
                          onChange={(e) =>
                            setModifiedChamberName(e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={handleModifyChamber}
                          disabled={!selectedChamber || !modifiedChamberName}
                        >
                          Modify Climatic Chamber
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form>
              </Card>
            </TabPane>

            <TabPane tab="Activate/Inactivate" key="5.3">
              <Card bordered>
                <AdminTable
                  fetchUrl={`${PY_API_URL}/api/get_climatic_chambers_admin`}
                  updateUrl={`${PY_API_URL}/api/update_climatic_chamber_status`}
                  columns={ClimaticChamberColumns}
                  primaryKey="name"
                />
              </Card>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Test Instruction" key="6">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Test Name">
                <Input
                  value={testName}
                  onChange={handleTestNameChange}
                  placeholder="Conditioning"
                />
              </Form.Item>
              <Form.Item label="Test Number" className="form-item-container">
                <div className="input-container">
                  <Input
                    value={testNumber}
                    onChange={handleTestNumberChange}
                    maxLength={9}
                    placeholder="T00001-A00"
                  />
                </div>
                <div className="tag-container">
                  <Tag icon={<ExclamationCircleOutlined />} color="warning">
                    Make sure the test number matches the uploaded PDF file. Can
                    only be 9 characters long
                  </Tag>
                </div>
              </Form.Item>

              <Form.Item>
                <Upload beforeUpload={handleUpload} accept=".pdf" maxCount={1}>
                  <Button icon={<UploadOutlined />}>Upload PDF</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitTestInstruction}>
                  Submit New Test Instruction
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AdminPanel;
