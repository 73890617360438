import React, { useState, useEffect, useRef } from "react";
import { Table, Button, message, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";

const AdminTable = ({
  fetchUrl, // API endpoint for fetching data
  updateUrl, // API endpoint for updating status
  columns, // Columns for the table
  primaryKey = "id", // Unique key for the table (e.g., "projectNumber", "testType")
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchInput = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(fetchUrl);
      if (response.status === 200) {
        let rawData = response.data;

        if (typeof rawData === "object") {
          rawData = Object.keys(rawData).map((key) => ({
            projectNumber: key, // ✅ Ensure projectNumber is set correctly
            ...rawData[key],
          }));
        }

        console.log("Processed Data for Table:", rawData);
        setData(rawData);
      } else {
        message.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Error fetching data");
    }
    setLoading(false);
  };

  // 🔹 Handle Status Toggle (Active/Inactive)
  const handleStatusToggle = async (record) => {
    try {
      const updatedStatus = record.status === "Active" ? "Inactive" : "Active";
      const response = await axios.post(updateUrl, {
        [primaryKey]: record[primaryKey],
        status: updatedStatus,
      });

      if (response.status === 200 && response.data.success) {
        message.success(`${record[primaryKey]} updated successfully.`);

        // Update local state to reflect the status change
        setData((prevData) =>
          prevData.map((item) =>
            item[primaryKey] === record[primaryKey]
              ? { ...item, status: updatedStatus }
              : item
          )
        );
      } else {
        message.error("Failed to update status.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      message.error("An error occurred while updating the status.");
    }
  };

  // 🛠 Generate Search Functionality for Columns
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  // 🔹 Make all columns searchable dynamically
  const searchableColumns = columns.map((col) => ({
    ...col,
    ...getColumnSearchProps(col.dataIndex),
  }));

  // 🔹 Add an action column for toggling Active/Inactive status
  const hasStatusColumn = columns.some((col) => col.dataIndex === "status");
  const updatedColumns = hasStatusColumn
    ? [
        ...searchableColumns,
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <Button
              type={record.status === "Active" ? "primary" : "primary"}
              danger={record.status === "Active"}
              style={
                record.status === "Active"
                  ? {}
                  : { backgroundColor: "#009900", borderColor: "#009900" }
              }
              onClick={() => handleStatusToggle(record)}
            >
              {record.status === "Active" ? "Inactivate" : "Activate"}
            </Button>
          ),
        },
      ]
    : searchableColumns;

  return (
    <Table
      columns={updatedColumns}
      dataSource={data}
      rowKey={primaryKey}
      loading={loading}
    />
  );
};

export default AdminTable;
