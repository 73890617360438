import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  MiniMap,
  Background,
  Panel,
  useReactFlow,
  getRectOfNodes,
  getTransformForBounds,
  BackgroundVariant,
  useStoreApi,
} from "reactflow";

import "reactflow/dist/style.css";
import Sidebar from "./Sidebar";
import IncompleteNodesSidebar from "./IncompleteNodesSidebar";
import UniqueTest from "./UniqueTest";
import "./index.css";
import { Modal, Form, Input, Button, Dropdown, Menu, Spin } from "antd";
import dagre from "dagre";
import axios from "../axiosConfig";
import { customFetch } from "../customFetch";
import { toPng } from "html-to-image";

const Heatmap = ({ clicks, moves }) => {
  return (
    <>
      {clicks.map((click, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            top: click.y,
            left: click.x,
            width: "8px",
            height: "8px",
            backgroundColor: "rgba(255, 0, 0, 0.2)",
            borderRadius: "100%",
            pointerEvents: "none",
          }}
        />
      ))}

      {moves.map((move, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            top: move.y,
            left: move.x,
            width: "8px",
            height: "8px",
            backgroundColor: "rgba(0, 255, 0, 0.2)",
            borderRadius: "100%",
            pointerEvents: "none",
          }}
        />
      ))}
    </>
  );
};

const PY_API_URL = process.env.REACT_APP_PY_API_URL;
const userName = localStorage.getItem("userName");
const userRole = localStorage.getItem("userRole");

async function trackButtonClick(buttonId) {
  try {
    const response = await axios.post(`${PY_API_URL}/api/track-button-click`, {
      button_id: buttonId,
      userName: userName,
    });
    console.log("Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error tracking button click:", error);
    throw error;
  }
}

const xd = [
  {
    id: "node-1",
    type: "testDetailer",
    position: { x: 0, y: 0 },
    data: { value: 123 },
  },
];

const minimapStyle = {
  height: 120,
};

//this is where the customized nodes go
const nodeTypes = { testDetailer: UniqueTest, custom_node: UniqueTest };

let id = 0;
const getId = () => `node_${id++}`;

function downloadImage(dataUrl) {
  const a = document.createElement("a");

  a.setAttribute("download", "TestPlan.png");
  a.setAttribute("href", dataUrl);
  a.click();
}

const imageWidth = 1024;
const imageHeight = 768;

const DownloadImageButton = () => {
  const { getNodes } = useReactFlow();
  const nodesBounds = getRectOfNodes(getNodes());
  const imageWidth = nodesBounds.width;
  const imageHeight = nodesBounds.height;
  const transform = getTransformForBounds(
    nodesBounds,
    imageWidth,
    imageHeight,
    0.5,
    2
  );

  const handleDownload = () => {
    toPng(document.querySelector(".react-flow__viewport"), {
      backgroundColor: "#EDEADE",
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(downloadImage);
    trackButtonClick("Download_Flowchart_As_Image");
  };

  return (
    <Menu.Item key="2" onClick={handleDownload}>
      Download Flowchart as Image
    </Menu.Item>
  );
};

const DnDFlow = () => {
  const reactFlowWrapper = useRef(null);
  const [initialNodes, setInitialNodes] = useState([]);
  const [inititalEdges, setInitialEdges] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    inititalEdges.map((edge) => ({
      ...edge,
      style: { strokeWidth: 2, stroke: "#FF0072" },
    }))
  );
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [hoveredEdge, setHoveredEdge] = useState(null);

  const onEdgeMouseEnter = useCallback(
    (_, edge) => {
      if (selectedEdge !== edge.id) setHoveredEdge(edge.id);
    },
    [selectedEdge]
  );

  const onEdgeMouseLeave = useCallback(() => {
    setHoveredEdge(null);
  }, []);

  const onEdgeClick = useCallback((_, edge) => {
    setSelectedEdge(edge.id);
    setHoveredEdge(null);
  }, []);

  const updatedEdges = edges.map((edge) => ({
    ...edge,
    style: {
      ...edge.style,
      strokeWidth:
        edge.id === selectedEdge ? 5 : edge.id === hoveredEdge ? 3 : 2,
      stroke: edge.id === selectedEdge ? "#FF4500" : edge.style.stroke,
    },
  }));

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const store = useStoreApi();
  // console.log("store", store.getState());
  const [jobNum, setJobNum] = useState(-1);
  const [selectedNode, setSelectedNode] = useState(null); //use this to highlight or zoom into the unfilled node when its clicked on the sidebar

  const [unfilledNodes, setUnfilledNodes] = useState([]);

  const [highestNodeId, setHighestNodeId] = useState(0);
  const [highestEdgeId, setHighestEdgeId] = useState(0);

  const [flowchartFeaturesUsed, setFlowchartFeaturesUsed] = useState(false);
  const [clicks, setClicks] = useState([]);
  const [moves, setMoves] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isCustomNodeModalVisible, setCustomNodeModalVisible] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const job_details = useLocation();
  const jobState = job_details.state || {}; // Safely fallback to an empty object

  const [flowChartID, setFlowChartID] = useState(jobState.flowChartID);

  const [form] = Form.useForm();

  useEffect(() => {
    const storedClicks =
      JSON.parse(localStorage.getItem("flowchart_clicks")) || [];
    setClicks(storedClicks);
  }, []);

  useEffect(() => {
    const storedMoves =
      JSON.parse(localStorage.getItem("flowchart_tracker")) || [];
    setMoves(storedMoves);
  }, []);

  const handleClick = (e) => {
    const newClick = { x: e.clientX, y: e.clientY };
    const updatedClicks = [...clicks, newClick];
    setClicks(updatedClicks);
    localStorage.setItem("flowchart_clicks", JSON.stringify(updatedClicks));
  };

  const handleMove = (e) => {
    const mousePosition = { x: e.clientX, y: e.clientY };
    const updatedMoves = [...moves, mousePosition];
    setMoves(updatedMoves);
    localStorage.setItem("flowchart_tracker", JSON.stringify(updatedMoves));
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////
  const handleInit = (instance) => setReactFlowInstance(instance);

  const getLayoutedElements = (
    nodes,
    edges,
    direction = "TB",
    rankSep = 200,
    nodeSep = 300
  ) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    dagreGraph.setDefaultEdgeLabel(() => ({}));

    dagreGraph.setGraph({
      rankdir: direction,
      ranksep: rankSep,
      nodesep: nodeSep,
    });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: 282, height: 190 });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.position = {
        x: nodeWithPosition.x - 280 / 2,
        x: nodeWithPosition.x - 280 / 2,
        y: nodeWithPosition.y - 190 / 2,
      };
    });

    return { nodes, edges };
  };

  const onLayout = useCallback(
    (direction = "TB") => {
      const rankSep = 150;
      const nodeSep = 150;
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(
          [...nodes],
          [...edges],
          direction,
          rankSep,
          nodeSep
        );
      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
      trackButtonClick("Arrange_Flowchart");
    },
    [nodes, edges, setNodes, setEdges]
  );

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////

  const handleTestClick = (node) => {
    onLayout("TB");
    setSelectedNode(node);
    if (reactFlowInstance) {
      const { x, y } = node.position;
      reactFlowInstance.fitBounds(
        {
          x,
          y,
          width: 500,
          height: 550,
        },
        {
          duration: 200,
          padding: 1,
        }
      );
    }
    trackButtonClick(`Incomplete_Node_${node}`);
  };

  const getClosestEdge = useCallback(
    (node) => {
      if (!node) {
        console.error("Node is undefined or null");
        return null;
      }
      const { nodeInternals } = store.getState();
      const storeNodes = Array.from(nodeInternals.values());
      const MIN_DISTANCE = userRole !== "Project Leader" ? 360 : 400;

      const closestNode = storeNodes.reduce(
        (res, n) => {
          if (n.id !== node.id) {
            const dx = n.positionAbsolute.x - node.positionAbsolute.x;
            const dy = n.positionAbsolute.y - node.positionAbsolute.y;
            const d = Math.sqrt(dx * dx + dy * dy);

            if (d < res.distance && d < MIN_DISTANCE && Math.abs(dx) < 200) {
              res.distance = d;
              res.node = n;
            }
          }
          console.log("Res", res);
          return res;
        },
        {
          distance: Number.MAX_VALUE,
          node: null,
        }
      );

      if (!closestNode.node) {
        return null;
      }
      const pl_margin = 250;
      const eng_margin = 150;
      const margin = userRole !== "Project Leader" ? eng_margin : pl_margin;
      const closeNodeIsSource =
        closestNode.node.positionAbsolute.y < node.positionAbsolute.y - margin;

      return {
        id: closeNodeIsSource
          ? `${closestNode.node.id}-${node.id}`
          : `${node.id}-${closestNode.node.id}`,
        source: closeNodeIsSource ? closestNode.node.id : node.id,
        target: closeNodeIsSource ? node.id : closestNode.node.id,
      };
    },
    [store]
  );

  const onNodeDrag = useCallback(
    (_, node) => {
      const closeEdge = getClosestEdge(node);
      setEdges((es) => {
        const nextEdges = es.filter((e) => e.className !== "temp");

        if (
          closeEdge &&
          !nextEdges.find(
            (ne) =>
              ne.source === closeEdge.source && ne.target === closeEdge.target
          )
        ) {
          closeEdge.className = "temp";
          closeEdge.style = {
            strokeWidth: 2,
            stroke: "#DA291C",
            strokeDasharray: "5,5",
          };
          nextEdges.push(closeEdge);
        }

        return nextEdges;
      });
    },
    [getClosestEdge, setEdges]
  );

  const onNodeDragStop = useCallback(
    (_, node) => {
      const closeEdge = getClosestEdge(node);

      setEdges((es) => {
        const nextEdges = es.filter((e) => e.className !== "temp");

        if (
          closeEdge &&
          !nextEdges.find(
            (ne) =>
              ne.source === closeEdge.source && ne.target === closeEdge.target
          )
        ) {
          closeEdge.style = {
            strokeWidth: 2,
            stroke: "#DA291C",
          };
          nextEdges.push(closeEdge);
        }

        return nextEdges;
      });
    },
    [getClosestEdge]
  );

  // useEffect(() => {
  //   setNodes((nds) =>
  //     nds.map((node) => {
  //       if (node['data']['nodeID'] === selectedNode['data']['nodeID']) {
  //         // create a new object here in order to notify react flow about the change
  //         node.data = {
  //           ...node.data,
  //           selected: true,
  //         };
  //       }
  //       else{
  //         node.data = {
  //           ...node.data,
  //           selected: false,
  //         };
  //       }
  //       return node;
  //     })
  //   );
  // }, [selectedNode, setNodes]);

  useEffect(() => {
    if (nodes.length === 0) {
      return;
    }
    console.log("hehe");
    if (nodes.length === 0) {
      console.log("No nodes available.");
      return;
    }

    let lowestID = Math.min(...nodes.map((node) => node.data.nodeID));

    setNodes((nodes) => {
      return nodes.map((node) => {
        return {
          ...node,
          data: { ...node.data, moduloID: lowestID },
        };
      });
    });
  }, []);

  const moduloNodeId = () => {
    console.log("hehe");
    if (nodes.length === 0) {
      console.log("No nodes available.");
      return;
    }

    let lowestID = Math.min(...nodes.map((node) => node.data.nodeID));

    setNodes((nodes) => {
      return nodes.map((node) => {
        return {
          ...node,
          data: { ...node.data, moduloID: lowestID },
        };
      });
    });
  };

  useEffect(() => {
    if (!selectedNode) return;
    setNodes((nds) => {
      const expandedWidth = 522;
      const expandedHeight = 776;
      const defaultWidth = 372;
      const defaultHeight = 250;

      const marginVertical = 576;
      const marginHorizontal = 150;

      const selectedNodeX = selectedNode.position.x;
      const selectedNodeY = selectedNode.position.y;

      return nds.map((node) => {
        if (node.id === selectedNode.id) {
          return {
            ...node,
            data: { ...node.data, selected: true },
            // width: expandedWidth,
            // height: expandedHeight,
          };
        } else {
          // handleTestClick(node);
          node.data = { ...node.data, selected: false };

          // Check if the node is within the vertical margin below the selected node
          const isBelow =
            node.position.y > selectedNodeY + defaultHeight &&
            node.position.y < selectedNodeY + defaultHeight + marginVertical;

          // Check if the node is within the horizontal margin next to the selected node
          const isNextTo =
            node.position.x > selectedNodeX + defaultWidth &&
            node.position.x < selectedNodeX + defaultWidth + marginHorizontal;
          // const isNextTo = node.position.x < (selectedNodeX + defaultWidth + marginHorizontal);
          // If the node is within the specified margins, adjust its position
          if (isBelow) {
            node.position.y += expandedHeight - defaultHeight;
          }

          if (isNextTo) {
            if (node.position.x < selectedNodeX) {
              node.position.x -= expandedWidth - defaultWidth;
            } else {
              node.position.x += expandedWidth - defaultWidth;
            }
          }
        }

        return node;
      });
    });
  }, [selectedNode, setNodes]);

  const handleUnfilledNodes = (nodes) => {
    setUnfilledNodes(nodes);
  };

  const onConnect = useCallback(
    (params) => {
      const newEdge = addEdge(
        { ...params, style: { strokeWidth: 2, stroke: "#DA291C" } },
        edges
      );
      setEdges(newEdge);
    },
    [edges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const initializeFlowChart = async () => {
    // Prevent API call if navigation comes from NavBar
    if (jobState.from === "NavBar") {
      console.log("Navigation came from NavBar, skipping API call.");
      return;
    }

    if (!flowChartID && jobState.jobID) {
      try {
        const response = await axios.post(`${PY_API_URL}/api/initFlowChart`, {
          jobID: jobState.jobID, // Pass the JobID to the backend
        });

        if (response.data.flowChartID) {
          console.log("FlowChartID initialized:", response.data.flowChartID);

          // Update the state with the new FlowChartID
          setFlowChartID(response.data.flowChartID);

          // Update jobState.flowChartID dynamically (if needed elsewhere)
          jobState.flowChartID = response.data.flowChartID;
        } else {
          console.error("Failed to initialize FlowChartID.");
        }
      } catch (error) {
        console.error("Error initializing FlowChartID:", error);
      }
    }
  };

  useEffect(() => {
    if (jobState.from !== "NavBar" && jobState.jobID && !flowChartID) {
      initializeFlowChart();
    } else if (jobState.from === "NavBar") {
      console.log(
        "Skipping flowchart initialization due to NavBar navigation."
      );
    } else if (!jobState.jobID) {
      console.warn("JobID is invalid or not available.");
    }
  }, [jobState.jobID, flowChartID]);

  // const initializeFlowChart = async () => {
  //   if (!flowChartID && jobState.jobID) {
  //     try {
  //       const response = await axios.post(`${PY_API_URL}/api/initFlowChart`, {
  //         jobID: jobState.jobID, // Pass the JobID to the backend
  //       });

  //       if (response.data.flowChartID) {
  //         console.log("FlowChartID initialized:", response.data.flowChartID);

  //         // Update the state with the new FlowChartID
  //         setFlowChartID(response.data.flowChartID);

  //         // Update jobState.flowChartID dynamically (if needed elsewhere)
  //         jobState.flowChartID = response.data.flowChartID;
  //       } else {
  //         console.error("Failed to initialize FlowChartID.");
  //       }
  //     } catch (error) {
  //       console.error("Error initializing FlowChartID:", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (jobState.jobID && !flowChartID) {
  //     initializeFlowChart();
  //   } else if (!jobState.jobID) {
  //     console.warn("JobID is invalid or not available.");
  //   }
  // }, [jobState.jobID, flowChartID]);

  // //new addnode
  const addNode = async (newNode) => {
    let flowChartId = flowChartID;

    // ✅ Skip DB interaction if entering from NavBar
    if (jobState.from === "NavBar") {
      console.log(
        "User entered from NavBar. Node added locally but not saved to DB."
      );
      setNodes((prevNodes) => [...prevNodes, newNode]);
      return;
    }

    // ✅ Ensure FlowChartID is initialized
    if (!flowChartId) {
      console.log("FlowChartID is undefined. Initializing...");
      try {
        const response = await axios.post(`${PY_API_URL}/api/initFlowChart`, {
          jobID: jobState.jobID,
        });

        if (response.data.flowChartID) {
          console.log("FlowChartID initialized:", response.data.flowChartID);
          setFlowChartID(response.data.flowChartID);
          flowChartId = response.data.flowChartID;
        } else {
          console.error("Failed to initialize FlowChartID.");
          return;
        }
      } catch (error) {
        console.error("Error initializing FlowChartID:", error);
        return;
      }
    }

    // ✅ Generate a temporary ID
    const tempId = `node_temp_${highestNodeId + 1}`;
    const initializedNode = { ...newNode, id: tempId };

    // ✅ Append node **immediately** to the flowchart UI
    setNodes((prevNodes) => [...prevNodes, initializedNode]);

    try {
      // ✅ Send request to backend
      const response = await axios.post(`${PY_API_URL}/api/registerNode`, {
        flowChartID: flowChartId,
        position: newNode.position,
        status: newNode.data.status,
        testingInstruction: newNode.data.testingInstruction,
      });

      if (response.data.nodeID) {
        const newNodeId = response.data.nodeID;

        // ✅ Correctly update the new node with the assigned NodeID
        setNodes((prevNodes) =>
          prevNodes.map((node) =>
            node.id === tempId
              ? {
                  ...node,
                  id: `node-${newNodeId}`,
                  data: { ...node.data, nodeID: newNodeId },
                }
              : node
          )
        );

        setHighestNodeId((prevId) => prevId + 1);
      } else {
        console.warn("Backend did not return a valid nodeID.");
      }
    } catch (error) {
      console.error(`Error registering node ${tempId}:`, error);
    }
  };

  //old addnode
  // const addNode = async (newNode) => {
  //   let flowChartId = flowChartID; // Use the existing flowChartID state

  //   // Check if flowChartID is not initialized and initialize it if necessary
  //   if (!flowChartId) {
  //     console.log("FlowChartID is undefined. Initializing...");
  //     try {
  //       const response = await axios.post(`${PY_API_URL}/api/initFlowChart`, {
  //         jobID: jobState.jobID, // Pass the JobID to the backend
  //       });

  //       if (response.data.flowChartID) {
  //         console.log("FlowChartID initialized:", response.data.flowChartID);

  //         // Update the flowChartID state and local variable
  //         setFlowChartID(response.data.flowChartID);
  //         flowChartId = response.data.flowChartID; // Update the local variable
  //       } else {
  //         console.error("Failed to initialize FlowChartID.");
  //         return; // Exit if FlowChartID cannot be initialized
  //       }
  //     } catch (error) {
  //       console.error("Error initializing FlowChartID:", error);
  //       return; // Exit if initialization fails
  //     }
  //   }

  //   const tempId = `node_temp_${highestNodeId + 1}`;
  //   const initializedNode = { ...newNode, id: tempId };

  //   console.log("Attempting to add node with FlowChartID:", flowChartId);

  //   // Update local state with the temporary ID
  //   setNodes((prevNodes) => [...prevNodes, initializedNode]);

  //   try {
  //     const response = await axios.post(`${PY_API_URL}/api/registerNode`, {
  //       flowChartID: flowChartId,
  //       position: initializedNode.position,
  //       status: initializedNode.data.status,
  //       testingInstruction: initializedNode.data.testingInstruction,
  //     });

  //     if (response.data.nodeID) {
  //       const newNodeId = response.data.nodeID;

  //       // Update the node with the backend-assigned ID
  //       setNodes((prevNodes) =>
  //         prevNodes.map((node) =>
  //           node.id === tempId
  //             ? {
  //                 ...node,
  //                 id: `node-${newNodeId}`,
  //                 data: { ...node.data, nodeID: newNodeId },
  //               }
  //             : node
  //         )
  //       );
  //       setHighestNodeId((prevId) => prevId + 1);
  //     } else {
  //       console.warn("Backend did not return a valid nodeID.");
  //     }
  //   } catch (error) {
  //     console.error(`Error registering node ${tempId}:`, error);
  //   }
  // };

  // const addNode = async (newNode) => {
  //   const tempId = `node_temp_${highestNodeId + 1}`;
  //   const initializedNode = { ...newNode, id: tempId };

  //   console.log("if job details got flowchart", job_details.state.flowChartID);

  //   console.log("Attempting to add node:", initializedNode);

  //   // Update local state with the temporary ID
  //   setNodes((prevNodes) => [...prevNodes, initializedNode]);

  //   // Register the node in the backend
  //   try {
  //     const response = await axios.post(`${PY_API_URL}/api/registerNode`, {
  //       flowChartID: job_details.state.jobID,
  //       position: initializedNode.position,
  //       status: initializedNode.data.status,
  //       testingInstruction: initializedNode.data.testingInstruction,
  //     });

  //     console.log("RegisterNode Response:", response.data);

  //     if (response.data.nodeID) {
  //       const newNodeId = response.data.nodeID;

  //       console.log(
  //         `Node registered with backend-assigned ID: node_${newNodeId}`
  //       );

  //       // Update the node with the backend-assigned ID
  //       setNodes((prevNodes) =>
  //         prevNodes.map((node) =>
  //           node.id === tempId
  //             ? {
  //                 ...node,
  //                 id: `node-${newNodeId}`,
  //                 data: { ...node.data, nodeID: newNodeId },
  //               }
  //             : node
  //         )
  //       );
  //       setHighestNodeId((prevId) => prevId + 1);
  //     } else {
  //       console.warn("Backend did not return a valid nodeID.");
  //     }
  //   } catch (error) {
  //     console.error(`Error registering node ${tempId}:`, error);
  //   }
  // };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const data = event.dataTransfer.getData("application/reactflow");
      const { type, testNumber, testName } = JSON.parse(data);

      if (typeof type === "undefined" || !type) {
        return;
      }

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      if (jobState.from === "NavBar") {
        console.log("User entered from NavBar, using old onDrop function.");

        if (type === "custom_node") {
          const newNode = {
            id: getId(), // Using the old ID generation method
            type,
            position,
            data: { testNumber, testName },
          };
          console.log("The new custom node (OLD logic) is: ", newNode);
          handleCustomNodeDrop(newNode);
        } else {
          const test_Name =
            testName[testNumber ? testNumber.substring(0, 6) : null];

          const newNode = {
            id: getId(),
            type,
            position,
            data: { testNumber, testName: test_Name },
          };
          console.log("The new Node (OLD logic) is: ", newNode);
          setNodes((nds) => nds.concat(newNode)); // Old way of adding nodes
        }
      } else {
        console.log(
          "User entered flowchart normally, using new onDrop function."
        );

        if (type === "custom_node") {
          const newNode = {
            id: `node-${highestNodeId + 1}`, // Use consistent ID format
            type,
            position,
            data: { testNumber, testName },
          };
          console.log("The new custom node (NEW logic) is: ", newNode);
          addNode(newNode); // Call addNode instead of handling it inline
        } else {
          const test_Name =
            testName[testNumber ? testNumber.substring(0, 6) : null];

          const newNode = {
            id: `node_${highestNodeId + 1}`, // Use consistent ID format
            type,
            position,
            data: { testNumber: testNumber, testName: test_Name },
          };
          console.log("The new Node (NEW logic) is: ", newNode);
          addNode(newNode); // Call addNode for regular nodes as well
        }
      }
    },
    [reactFlowInstance, highestNodeId]
  );

  //old ondrop
  // const onDrop = useCallback(
  //   (event) => {
  //     event.preventDefault();
  //     const data = event.dataTransfer.getData("application/reactflow");
  //     const { type, testNumber, testName } = JSON.parse(data);

  //     if (typeof type === "undefined" || !type) {
  //       return;
  //     }

  //     const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
  //     const position = reactFlowInstance.project({
  //       x: event.clientX - reactFlowBounds.left,
  //       y: event.clientY - reactFlowBounds.top,
  //     });

  //     if (type === "custom_node") {
  //       const newNode = {
  //         id: `node-${highestNodeId + 1}`, // Use consistent ID format
  //         type,
  //         position,
  //         data: { testNumber, testName },
  //       };
  //       console.log("The new custom node is: ", newNode);
  //       addNode(newNode); // Call addNode instead of handling it inline
  //     } else {
  //       const test_Name =
  //         testName[testNumber ? testNumber.substring(0, 6) : null];

  //       const newNode = {
  //         id: `node_${highestNodeId + 1}`, // Use consistent ID format
  //         type,
  //         position,
  //         data: { testNumber: testNumber, testName: test_Name },
  //       };
  //       console.log("The new Node is: ", newNode);
  //       addNode(newNode); // Call addNode for regular nodes as well
  //     }
  //   },
  //   [reactFlowInstance, highestNodeId] // Include highestNodeId in dependencies
  // );

  // const onDrop = useCallback(
  //   (event) => {
  //     event.preventDefault();
  //     const data = event.dataTransfer.getData("application/reactflow");
  //     const { type, testNumber, testName } = JSON.parse(data);

  //     if (typeof type === "undefined" || !type) {
  //       return;
  //     }

  //     const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
  //     const position = reactFlowInstance.project({
  //       x: event.clientX - reactFlowBounds.left,
  //       y: event.clientY - reactFlowBounds.top,
  //     });

  //     if (type === "custom_node") {
  //       const newNode = {
  //         id: getId(),
  //         type,
  //         position,
  //         data: { testNumber, testName },
  //       };
  //       console.log("the new custom node is: ", newNode);
  //       handleCustomNodeDrop(newNode);
  //     } else {
  //       const test_Name =
  //         testName[testNumber ? testNumber.substring(0, 6) : null];

  //       const newNode = {
  //         id: getId(),
  //         type,
  //         position,
  //         data: { testNumber: testNumber, testName: test_Name },
  //       };
  //       console.log("the new Node is: ", newNode);
  //       setNodes((nds) => nds.concat(newNode));
  //     }
  //   },
  //   [reactFlowInstance]
  // );

  const handleCustomNodeDrop = (node) => {
    setCurrentNode(node);
    setCustomNodeModalVisible(true);
  };

  const handleCustomNodeSave = (values) => {
    const updatedNode = {
      ...currentNode,
      data: { ...currentNode.data, ...values }, // Merge existing data with form values
    };
    console.log("the updated node is: ", updatedNode);
    setNodes((nds) => nds.concat(updatedNode));
    setCustomNodeModalVisible(false);
  };

  // const job_details = useLocation();

  const nodesChangedRef = useRef(false); // Ref to track if nodes have changed
  console.log("602:", job_details);

  useEffect(() => {
    // Delay the execution of the effect
    // const timer = setTimeout(() => {
    console.log("604:", nodesChangedRef);

    // Check if nodes have changed, if they are populated, and if the effect hasn't been run yet
    if (
      !nodesChangedRef.current &&
      nodes.length > 0 &&
      job_details.state &&
      job_details.state.nodeID
    ) {
      const nodeID = parseInt(job_details.state.nodeID.replace("node-", ""));
      console.log("609:", nodes);

      for (const node of nodes) {
        console.log("nodeID: ", nodeID);
        if (node.data.nodeID === nodeID) {
          console.log("node:", node);
          setSelectedNode(node);
          handleTestClick(node);
          break;
        }
      }

      // Mark that the nodes have been processed
      nodesChangedRef.current = true;
    }
    // }, 500); // Delay of 0.5 seconds

    // // Cleanup function to clear the timeout if the component unmounts or dependencies change
    // return () => clearTimeout(timer);
  }, [nodes]); // Dependency only on nodes

  useEffect(() => {
    localStorage.removeItem("job_details");
    localStorage.setItem("job_details", JSON.stringify(job_details));
  }, [job_details]);

  const navigate = useNavigate();
  if (
    job_details.state.submissionStatus == 0 &&
    job_details.state.from !== "ViewJob" &&
    job_details.state.from !== "NavBar"
  ) {
    navigate("/addjob", { state: job_details });
  }
  if (!job_details.state.jobID) {
    job_details.state.jobID = job_details.state.jobOrderID;
  }
  // gets the nodes
  useEffect(() => {
    if (job_details.state.jobID !== -1) {
      setIsLoading(true);
      customFetch(`${PY_API_URL}/api/get_nodes/${job_details.state.jobID}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // console.log("the node data is:", data);
          setInitialNodes(data);
          setNodes(data);
          // moduloNodeId();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    // console.log(job_details.state.jobID);
    if (job_details.state.jobID !== -1) {
      customFetch(`${PY_API_URL}/api/get_edges/${job_details.state.jobID}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const styledEdges = data.map((edge) => ({
            ...edge,
            style: { strokeWidth: 2, stroke: "#DA291C" },
          }));
          setInitialEdges(styledEdges);
          setEdges(styledEdges);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  function convertToCSV(objArray, edgesArray) {
    if (objArray && edgesArray) {
      const nodeArray =
        typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
      const edgeArray =
        typeof edgesArray !== "object" ? JSON.parse(edgesArray) : edgesArray;
      let csv =
        "id, testName, testNumber, x, y, type, EdgeID, edgeConnection, source, target\n";

      nodeArray.forEach((node) => {
        const { data, id, position, type } = node;
        const { testName, testNumber } = data;
        const { x, y } = position;

        csv += `${id},${testName},${testNumber},${x},${y},${type}\n`;
      });

      edgeArray.forEach((edge) => {
        const { EdgeID, source, target, id } = edge;
        csv += `,,,,,,${EdgeID}, ${id},${source},${target}\n`;
      });

      return csv;
    }
  }
  const nodeCSV = convertToCSV(
    job_details.state.nodes === undefined ? nodes : job_details.state.nodes,
    job_details.state.edges === undefined ? edges : job_details.state.edges
  );

  function downloadCSV(csv, filename) {
    const csvFile = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(csvFile);
    link.download = filename;
    link.click();
  }

  const onDownloadFlowchart = () => {
    console.log("the nodes are: ", nodes);
    console.log("the edges are: ", edges);
    downloadCSV(nodeCSV, "flowchart_data.csv");
  };

  const fetchHighestIds = async () => {
    try {
      const nodeResponse = await customFetch(
        `${PY_API_URL}/api/nodes/highest-id`
      );
      if (!nodeResponse.ok) {
        throw new Error(
          `Error fetching highest node ID: ${nodeResponse.statusText}`
        );
      }
      const nodeData = await nodeResponse.json();
      const highestNodeId = nodeData.highestNodeId;

      const edgeResponse = await customFetch(
        `${PY_API_URL}/api/edges/highest-id`
      );
      if (!edgeResponse.ok) {
        throw new Error(
          `Error fetching highest edge ID: ${edgeResponse.statusText}`
        );
      }
      const edgeData = await edgeResponse.json();
      const highestEdgeId = edgeData.highestEdgeId;

      setHighestNodeId(highestNodeId);
      setHighestEdgeId(highestEdgeId);
    } catch (error) {
      console.error("Error fetching highest IDs:", error);
    }
  };

  useEffect(() => {
    fetchHighestIds();
  }, []);

  const onUploadFlowchart = (
    event,
    currentHighestNodeId,
    currentHighestEdgeId
  ) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      const lines = csvData.split("\n");

      const nodes = [];
      const edges = [];
      const oldToNewNodeIdMap = {};
      const oldToNewEdgeIdMap = {};

      let nextNodeId = currentHighestNodeId + 1;
      let nextEdgeId = currentHighestEdgeId + 1;
      console.log("nextNodeId is: ", nextNodeId);
      console.log("nextEdgeId is: ", nextEdgeId);

      for (let i = 1; i < lines.length; i++) {
        const cols = lines[i].split(",");

        if (cols[6] === undefined || cols[6] === "") {
          const oldNodeId = cols[0];
          const testName = cols[1];
          const testNumber = cols[2];
          const positionX = parseFloat(cols[3]);
          const positionY = parseFloat(cols[4]);

          if (!isNaN(positionX) && !isNaN(positionY)) {
            const newNodeId = `node-${nextNodeId++}`;
            oldToNewNodeIdMap[oldNodeId] = newNodeId;

            const node = {
              id: newNodeId,
              type: "testDetailer",
              position: { x: positionX, y: positionY },
              data: { testNumber, testName },
            };

            nodes.push(node);
          }
        }
      }

      for (let i = 1; i < lines.length; i++) {
        const cols = lines[i].split(",");

        if (cols[6] !== undefined && cols[6] !== "") {
          const oldEdgeId = cols[7].trim();
          const oldSourceId = cols[8];
          const oldTargetId = cols[9];

          // Generate new unique edge ID
          const newEdgeId = `edge-${nextEdgeId++}`;
          oldToNewEdgeIdMap[oldEdgeId] = newEdgeId;

          // Remap source and target IDs to the new node IDs
          const newSourceId = oldToNewNodeIdMap[oldSourceId] || oldSourceId;
          const newTargetId = oldToNewNodeIdMap[oldTargetId] || oldTargetId;

          const edge = {
            id: newEdgeId,
            source: newSourceId,
            target: newTargetId,
            style: { strokeWidth: 2, stroke: "#DA291C" },
          };

          edges.push(edge);
        }
      }

      setNodes([...nodes]);
      setEdges([...edges]);

      if (reactFlowInstance) {
        reactFlowInstance.fitView();
      }
    };

    reader.readAsText(file);
  };

  const handleUploadChange = (event) => {
    onUploadFlowchart(event, highestNodeId, highestEdgeId);
    setFlowchartFeaturesUsed(true);
    trackButtonClick("Upload_Flowchart");
  };
  const areNodesEmpty =
    job_details.state.nodes === "[]" || job_details.state.from === "NavBar";
  const menuItems = [
    <Menu.Item
      key="1"
      onClick={() => {
        onDownloadFlowchart();
        trackButtonClick("Download_Flowchart_As_Csv");
      }}
    >
      Download Flowchart as CSV
    </Menu.Item>,
    <DownloadImageButton />,
  ];

  if (!flowchartFeaturesUsed && areNodesEmpty) {
    menuItems.push(
      <Menu.Item key="3">
        <label htmlFor="fileInput">Upload Flowchart</label>
        <input
          id="fileInput"
          type="file"
          accept=".csv"
          style={{ display: "none" }}
          onChange={handleUploadChange}
        />
      </Menu.Item>
    );
  }

  console.log(flowchartFeaturesUsed);
  console.log(areNodesEmpty);

  const menu = <Menu>{menuItems}</Menu>;

  const handleCCMBack = (index) => {
    job_details["state"]["unfilledNodes"] = unfilledNodes;
    navigate("/addJob", {
      state: {
        fromFlowChartBackBtn: true,
        sendForApprovalStatus: job_details.state.sendForApprovalStatus,
        nodesAmount: nodes.length,
        state: job_details.state,
      },
    });
    trackButtonClick("Flowchart_Back");
  };

  const handleSubmit = (index) => {
    for (const edge of edges) {
      const sourceNode = edge["source"];
      const targetNode = edge["target"];
      var sourcePosition = 0;
      var targetPosition = 0;
      for (const node of nodes) {
        if (node["id"] == sourceNode) {
          sourcePosition = node["position"];
        }
        if (node["id"] == targetNode) {
          targetPosition = node["position"];
        }
      }
      edge["source"] = sourcePosition;
      edge["target"] = targetPosition;
    }
    job_details["state"]["unfilledNodes"] = unfilledNodes;
    console.log("the job_details.state is: ", job_details.state);
    console.log("the edges: ", edges);
    console.log("the nodes: ", nodes);
    navigate("/addJob", {
      state: {
        fromFlowChart: true,
        nodes: nodes,
        edges: edges,
        state: job_details.state,
      },
    });
    trackButtonClick("Save_And_Exit");
  };

  const handleSave = async () => {
    const edgesWithNodeIds = edges.map((edge) => {
      const sourceNodeData = nodes.find(
        (node) =>
          node.position.x === edge.source.x && node.position.y === edge.source.y
      );
      const targetNodeData = nodes.find(
        (node) =>
          node.position.x === edge.target.x && node.position.y === edge.target.y
      );

      return {
        ...edge,
        source: sourceNodeData ? sourceNodeData.id : edge.source,
        target: targetNodeData ? targetNodeData.id : edge.target,
      };
    });

    // Validate nodes before saving
    const validNodes = nodes.map((node) => {
      if (!node.data.nodeID) {
        console.warn(`Node ${node.id} is missing a valid nodeID.`);
      }
      return node;
    });

    const nodesJson = JSON.stringify(validNodes);
    const edgesJson = JSON.stringify(edgesWithNodeIds);

    console.log("Saving flowchart...");
    console.log("Job ID:", job_details.state.jobID);
    console.log("Nodes JSON:", nodesJson);
    console.log("Edges JSON:", edgesJson);

    try {
      setIsLoading(true);

      const response = await axios.post(`${PY_API_URL}/api/update-flowchart`, {
        jobID: job_details.state.jobID,
        nodes: nodesJson,
        edges: edgesJson,
      });

      console.log("Save Response:", response.data);

      if (response.data.nodes) {
        console.log("Updated nodes from backend:", response.data.nodes);
        setNodes(response.data.nodes);
      } else {
        console.warn("No nodes returned from backend.");
      }

      if (response.data.edges) {
        console.log("Updated edges from backend:", response.data.edges);
        setEdges(response.data.edges);
      } else {
        console.warn("No edges returned from backend.");
      }

      trackButtonClick("Save_Flowchart_btn");
    } catch (error) {
      console.error("Error saving flowchart:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //   const handleSave = async (index) => {
  //     // Step 1: Clone edges to avoid direct modification
  //     const edgesWithPositions = edges.map(edge => {
  //         const sourceNode = edge['source'];
  //         const targetNode = edge['target'];

  //         const sourceNodeData = nodes.find(node => node['id'] === sourceNode);
  //         const targetNodeData = nodes.find(node => node['id'] === targetNode);

  //         return {
  //             ...edge,
  //             source: sourceNodeData ? sourceNodeData['position'] : edge['source'],
  //             target: targetNodeData ? targetNodeData['position'] : edge['target']
  //         };
  //     });

  //     job_details['state']['unfilledNodes'] = unfilledNodes;
  //     const nodesJson = JSON.stringify(nodes);
  //     const edgesJson = JSON.stringify(edgesWithPositions);

  //     try {
  //         setIsLoading(true);
  //         const response = await axios.post(`${PY_API_URL}/api/update-flowchart`, {
  //             jobID: job_details['state']['jobID'],
  //             nodes: nodesJson,
  //             edges: edgesJson
  //         });

  //         if (response.data && response.data.edges) {
  //             setEdges(response.data.edges);
  //         }

  //         trackButtonClick('Save_Flowchart_btn');
  //     } catch (error) {
  //         console.error("Error saving flowchart:", error);
  //     } finally {
  //         setIsLoading(false);
  //     }
  // };

  const handleBack = (index) => {
    for (const edge of edges) {
      const sourceNode = edge["source"];
      const targetNode = edge["target"];
      var sourcePosition = 0;
      var targetPosition = 0;
      for (const node of nodes) {
        if (node["id"] == sourceNode) {
          sourcePosition = node["position"];
        }
        if (node["id"] == targetNode) {
          targetPosition = node["position"];
        }
      }
      edge["source"] = sourcePosition;
      edge["target"] = targetPosition;
    }
    navigate("/");
    trackButtonClick("Flowchart_Back");
  };

  const showConfirm_back = () => {
    Modal.confirm({
      title: "Are you sure you want to go back?",
      onOk() {
        handleCCMBack();
      },
      onCancel() {
        console.log("Back cancelled");
      },
    });
  };

  return (
    <>
      <div
        className="dndflow"
        style={{ height: "101%" }}
        onClick={handleClick}
        onMouseMove={handleMove}
      >
        {/* <Heatmap clicks={clicks} moves={moves} /> */}
        {/* <ReactFlowProvider> */}
        {(userRole === "Admin" ||
          userRole === "Engineer" ||
          userRole === "Project Leader") &&
        job_details.state.from !== "ViewJob" ? (
          <Sidebar />
        ) : null}
        {userRole === "Cost Center Manager" &&
        job_details.state.from === "NavBar" ? (
          <Sidebar />
        ) : null}
        {(userRole === "Project Leader" || userRole === "Admin") &&
          job_details.state.from !== "ViewJob" &&
          job_details.state.from !== "NavBar" && (
            <IncompleteNodesSidebar
              nodes={nodes}
              edges={edges}
              onTestClick={handleTestClick}
              onNodesUnfilled={handleUnfilledNodes}
            />
          )}
        {isLoading ? (
          <div className="loading-overlay">
            <Spin tip="Loading..." size="large">
              <div className="content" />
            </Spin>
          </div>
        ) : (
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            {(userRole === "Admin" ||
              userRole === "Engineer" ||
              userRole === "Project Leader") &&
            job_details.state.from !== "ViewJob" &&
            job_details.state.from !== "NavBar" ? (
              <ReactFlow
                nodes={nodes}
                edges={updatedEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                onInit={handleInit}
                onEdgeMouseEnter={onEdgeMouseEnter}
                onEdgeMouseLeave={onEdgeMouseLeave}
                onEdgeClick={onEdgeClick}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onNodeDrag={onNodeDrag}
                onNodeDragStop={onNodeDragStop}
                fitView
              >
                <Panel position="top-right">
                  {
                    <Button
                      size={"large"}
                      style={{ margin: "0 20px" }}
                      onClick={() => onLayout("TB")}
                    >
                      Arrange Flowchart
                    </Button>
                  }
                  {
                    <Dropdown overlay={menu}>
                      <Button size={"large"} style={{ margin: "0 20px" }}>
                        Actions
                      </Button>
                    </Dropdown>
                  }
                </Panel>
                <Controls />
                <MiniMap
                  style={minimapStyle}
                  nodeColor={"#DA291C"}
                  zoomable
                  pannable
                />
                <Background color="#000" gap={16} size={1.3} />
              </ReactFlow>
            ) : userRole === "Test Technician" &&
              job_details.state.from !== "ViewJob" ? (
              <ReactFlow
                nodes={nodes}
                edges={updatedEdges}
                onNodesChange={onNodesChange}
                // onEdgesChange={onEdgesChange}
                // onConnect={onConnect}
                nodeTypes={nodeTypes}
                onInit={handleInit}
                onEdgeMouseEnter={onEdgeMouseEnter}
                onEdgeMouseLeave={onEdgeMouseLeave}
                onEdgeClick={onEdgeClick}
                // onDragOver={onDragOver}
                // edgesUpdatable={false}
                // edgesFocusable={false}
                // edgesFocusable={false}
                // nodesDraggable={true}
                // nodesConnectable={false}
                // nodesFocusable={true}
                // draggable={true}
                panOnDrag={true}
                elementsSelectable={true}
                fitView
              >
                <Panel position="top-right">
                  {
                    <Button
                      size={"large"}
                      style={{ margin: "0 20px" }}
                      onClick={() => onLayout("TB")}
                    >
                      Arrange Flowchart
                    </Button>
                  }
                  {
                    <Dropdown overlay={menu}>
                      <Button size={"large"} style={{ margin: "0 20px" }}>
                        Actions
                      </Button>
                    </Dropdown>
                  }
                </Panel>
                <MiniMap
                  style={minimapStyle}
                  nodeColor={"#DA291C"}
                  zoomable
                  pannable
                />
                <Background color="#000" gap={16} size={1.3} />
              </ReactFlow>
            ) : (
              // <ReactFlow
              //   nodes={nodes}
              //   edges={edges}
              //   onNodesChange={onNodesChange}
              //   onEdgesChange={onEdgesChange}
              //   onConnect={onConnect}
              //   nodeTypes={nodeTypes}
              //   onInit={handleInit}
              //   onDrop={onDrop}
              //   onDragOver={onDragOver}
              //   edgesUpdatable={true}
              //   edgesFocusable={true}
              //   nodesDraggable={false}
              //   onNodeDrag={onNodeDrag}
              //   onNodeDragStop={onNodeDragStop}
              //   nodesConnectable={false}
              //   nodesFocusable={false}
              //   draggable={false}
              //   panOnDrag={true}
              //   elementsSelectable={false}
              //   fitView
              // >
              <ReactFlow
                nodes={nodes}
                edges={updatedEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                onInit={handleInit}
                onEdgeMouseEnter={onEdgeMouseEnter}
                onEdgeMouseLeave={onEdgeMouseLeave}
                onEdgeClick={onEdgeClick}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onNodeDrag={onNodeDrag}
                onNodeDragStop={onNodeDragStop}
                fitView
              >
                <Panel position="top-right" style={{ marginRight: "3%" }}>
                  {job_details.state.from === "NavBar" && (
                    <Button
                      size={"large"}
                      style={{ margin: "0 20px" }}
                      onClick={() => onLayout("TB")}
                    >
                      Arrange Flowchart
                    </Button>
                  )}
                  {
                    <Dropdown overlay={menu}>
                      <Button size={"large"} style={{ margin: "0 20px" }}>
                        Actions
                      </Button>
                    </Dropdown>
                  }
                  {/* <DownloadButton /> */}
                </Panel>
                <Controls />
                <MiniMap
                  style={minimapStyle}
                  nodeColor={"#DA291C"}
                  zoomable
                  pannable
                />
                <Background color="#000" gap={16} size={1.3} />
              </ReactFlow>
            )}
          </div>
        )}
      </div>
      <Modal
        title="Custom Node Information"
        visible={isCustomNodeModalVisible}
        onCancel={() => setCustomNodeModalVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              handleCustomNodeSave(values);
            })
            .catch((errorInfo) => {
              console.error("Validation failed:", errorInfo);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="custom_node_form"
          style={{ width: "100%" }}
        >
          <Form.Item
            name="testName"
            label="Test Name"
            rules={[{ required: true, message: "Please input the test name!" }]}
            style={{ width: "100%" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="testNumber"
            label="Test Number"
            rules={[
              { required: true, message: "Please input the test number!" },
            ]}
            style={{ width: "100%" }}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* {(userRole === 'Engineer') && <button className='submitFC-btn' onClick={handleSubmit}>Submit</button>} */}
      <div class="cost-container">
        {/* <button onClick={() => setNodes(getLayoutedElements(nodes, edges, 'TB'))}>Vertical Layout</button> */}
        {/* {(job_details.state.nodes === '[]')&&(userRole === 'Engineer' || userRole === 'Project Leader'|| userRole === 'Admin') && <Dropdown overlay={menu}><Button size={'large'} style={{ margin: '0 20px' }}>Flowchart Features</Button></Dropdown>} */}

        {(userRole === "Engineer" ||
          userRole === "Project Leader" ||
          userRole === "Admin") &&
          job_details.state.from !== "ViewJob" &&
          job_details.state.from !== "NavBar" && (
            <Button
              size={"large"}
              style={{ marginBottom: "0" }}
              danger
              onClick={showConfirm_back}
            >
              Back
            </Button>
          )}
        {job_details.state.jobID !== -1 &&
          (userRole === "Engineer" ||
            userRole === "Project Leader" ||
            userRole === "Admin") &&
          job_details.state.from !== "ViewJob" &&
          job_details.state.from !== "NavBar" && (
            <button className="submitFC-btn" onClick={handleSave}>
              Save
            </button>
          )}
        {(userRole === "Engineer" ||
          userRole === "Project Leader" ||
          userRole === "Admin") &&
          job_details.state.from !== "ViewJob" &&
          job_details.state.from !== "NavBar" && (
            <button className="submitFC-btn" onClick={handleSubmit}>
              Save and Exit
            </button>
          )}
      </div>
      {userRole === "Cost Center Manager" &&
        job_details.state.from !== "ViewJob" &&
        job_details.state.from !== "NavBar" && (
          <Button
            style={{ marginLeft: "1%", marginBottom: "0" }}
            size={"large"}
            danger
            onClick={handleCCMBack}
          >
            Back
          </Button>
        )}
      {userRole === "Test Technician" &&
        job_details.state.from !== "ViewJob" &&
        job_details.state.from !== "NavBar" && (
          <Button
            style={{ marginLeft: "1%", marginBottom: "0" }}
            size={"large"}
            danger
            onClick={handleBack}
          >
            Back
          </Button>
        )}
      {(job_details.state.from === "ViewJob" ||
        job_details.state.from === "NavBar") && (
        <Button
          style={{ marginLeft: "1%", marginBottom: "0" }}
          size={"large"}
          danger
          onClick={handleBack}
        >
          Back
        </Button>
      )}
    </>
  );
};

// export default DnDFlow;
export default () => (
  <ReactFlowProvider>
    <DnDFlow />
  </ReactFlowProvider>
);
